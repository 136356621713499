<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 card px-half">
        <dx-data-grid
          id="amazonReportsGrid"
          ref="amazonGridRef"
          height="calc(100vh - 200px)"
          :data-source="amazonReportsDataSource"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @option-changed="onOptionChanged"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <dx-search-panel
            :highlight-search-text="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-remote-operations :paging="true" :filtering="true" :sorting="true" />
          <dx-selection
            show-check-boxes-mode="always"
            select-all-mode="page"
            :allow-select-all="true"
            :width="10"
            mode="multiple"
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <dx-column data-field="title" cell-template="titleCellTemplate" />
          <template #titleCellTemplate="{data}">
            <item-info
              :id="data.data.productId"
              :title="data.data.title"
              :asin="data.data.asin"
              :upc="data.data.upc"
              :msku="data.data.msku"
              :image-url="data.data.smallImage"
              :large-image-url="data.data.largeImage"
              :is-printable="false"
              :is-truncated="false"
              :item-info-visible="false"
              @on-emit-catalog-item-info="openProductDetails"
            />
          </template>
          <dx-column data-field="orderId" :width="180" cell-template="orderIdTemplate" />
          <template #orderIdTemplate="{data}">
            <router-link :to="{ name: 'amazon-sales-report', query: { orderId: data.value}}">
              {{ data.value }}
            </router-link>
          </template>
          <dx-column data-field="quantity" caption="Qty" :width="70" />
          <dx-column data-field="status" :width="120" cell-template="statusTemplate" />
          <template #statusTemplate="{data}">
            <div v-if="data.value" :class="resolveStatus(data.value)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="purchaseDate" :width="140" data-type="date" :format="'yyyy-MM-dd HH:mm'" />
          <dx-column data-field="returnDate" :width="120" data-type="date" :format="'yyyy-MM-dd'" />
          <dx-column data-field="tracking" :width="140" cell-template="trackingTemplate" />
          <template #trackingTemplate="{data}">
            <div class="text-info" @click="getTrackingStatus(data.data)">
              {{ data.value }}
            </div>
          </template>
          <dx-column data-field="customerComment" caption="Comment" :width="90" alignment="center" cell-template="customerCommentTemplate" />
          <template #customerCommentTemplate="{data}">
            <div v-if="data.value">
              <p-icon v-b-tooltip.hover="{ variant: 'primary' }" name="bi-chat-left-text" :title="data.value" />
            </div>
          </template>
          <dx-column data-field="reason" :width="140" />
          <dx-column data-field="returnBarcode" caption="Barcode" :width="140" />
          <dx-column data-field="detailedDisposition" :width="140" />
          <dx-column data-field="channel" :width="90" />
          <dx-column data-field="orderAmount" caption="Order" :width="90" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="refundAmount" caption="Refund" :width="90" :format="{ type: 'currency', precision: 2 }" />
          <dx-column data-field="labelCost" :width="90" :format="{ type: 'currency', precision: 2 }" />
          <template #toolbarFilters>
            <div class="d-flex">
              <div class="mr-half">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.detailedDisposition"
                  :data-source="dispositions"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <div class="mr-half">
                <dx-util-select-box
                  v-model="filter.channel"
                  :data-source="fulfillmentMethods"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
              <date-selection default-date="current_month" @setdates="onDateSelection" />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
    <!--Begin:: Product Details -->
    <product-details :product-id="selectedProductId"
      :show-product="isProductDetailsVisible"
      @close="isProductDetailsVisible=false,selectedProductId=null"
    />
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this report module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import { ReportTypeEnum } from '@/enums'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'
import { currencyFormatter } from '@core/utils/filter'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import { Notify } from '@/@robustshell/utils'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ItemInfo from '@/views/apps/outbound/fba-shipments/components/ItemInfo.vue'
// import moment from 'moment'
// import { v4 as uuidv4 } from 'uuid'
import { AmazonReturnsDataSource } from './amazonReturnsStore.js'

// import Pager from '@core/dev-extreme/utils/pager'
// import ReportDateRangeEnum from '@/enums'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'light-box': () => import('vue-cool-lightbox'),
    'item-info': ItemInfo,
    'date-selection': DateSelection,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      dispositions: [
        { text: 'All Dispositions', value: 'ALL' },
        { text: 'Sellable', value: 'SELLABLE' },
        { text: 'Defective', value: 'DEFECTIVE' },
        { text: 'Customer Damaged', value: 'CUSTOMER_DAMAGED  ' },
      ],
      fulfillmentMethods: [
        { text: 'All Channels', value: 'ALL' },
        { text: 'Amazon', value: 'Amazon' },
        { text: 'Merchant', value: 'Merchant' },
      ],
      selectedReportType: ReportTypeEnum.ASIN.value,
      amazonReportsDataSource: AmazonReturnsDataSource,
      filter: {
        detailedDisposition: 'ALL',
        channel: 'ALL',
        q: '',
        beginDate: null,
        endDate: null,
      },
      defaultImg: defaultImageUrl,
      selectedProductId: null,
      isProductDetailsVisible: false,
      images: [],
      index: null,
      storeId: null,
      reportItem: '',
      hoveredItem: '',
      isPopoverVisible: '',
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const dataGridIns = this.$refs.amazonGridRef.instance
      return dataGridIns
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
  },
  created() {
    const orderId = this.$route.query.orderId
    this.filter.q = orderId
    this.getStores()
  },
  mounted() {
    this.loadData()
    this.$nextTick(() => {
      this.dataGrid.getComponent('searchPanel').setValue('Your search value')
    })
  },
  methods: {
    getCurrencyFormat(value) {
      return currencyFormatter(value)
    },
    openProductDetails(e) {
      this.selectedProductId = e.param
      this.isProductDetailsVisible = true
    },
    loadData() {
      AmazonSalesFilter.setDefaultFilters()
      AmazonSalesFilter.beginDate = this.beginDate
      AmazonSalesFilter.endDate = this.endDate
      const filters = AmazonSalesFilter.getFilters()
      AmazonReturnsDataSource.searchValue(filters)
    },
    searchByFilter() {
      AmazonSalesFilter.storeId = this.selectedStore
      AmazonSalesFilter.beginDate = this.filter.beginDate
      AmazonSalesFilter.endDate = this.filter.endDate
      AmazonSalesFilter.marketplace = this.filter.marketplace
      AmazonSalesFilter.channel = this.filter.channel
      AmazonSalesFilter.q = this.filter.q
      const filters = AmazonSalesFilter.getFilters()
      AmazonReturnsDataSource.searchValue(filters)
      AmazonReturnsDataSource.reload()
    },
    onDateSelection(e) {
      this.filter.beginDate = e.beginDate
      this.filter.endDate = e.endDate
      this.searchByFilter()
    },
    resetAndRefreshDataGrid() {
      this.selectedStore = 0
      this.filter.q = ''
      const filters = AmazonSalesFilter.getFilters()
      this.dataSource.searchValue(filters)
      this.dataSource.reload()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.searchByFilter()
      }
    },
    resolveStatus(status) {
      if (status === 'Unit returned to inventory') {
        return 'badge badge-warning'
      }
      if (status === 'Unshipped') {
        return 'badge badge-danger'
      }
      return 'badge badge-primary'
    },
    resolveByValue(value) {
      const profit = parseInt(value, 10)
      if (profit >= 0) {
        return 'badge badge-light-success'
      }
      return 'badge badge-light-danger'
    },
    getTrackingStatus(data) {
      const orderId = data.orderId
      const fedexUrl = `https://www.fedex.com/fedextrack?trknbr=${orderId}`
      const upsUrl = `https://www.ups.com/track?HTMLVersion=5.0&loc=en_US&Requester=UPSHome&WBPM_lid=homepage%2Fct1.html_pnl_trk&track.x=Track&trackNums=${orderId}/`
      const uspsUrl = `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${orderId}%2C&tABt=false`

      switch (data.carrier) {
        case 'UPS':
          window.open(upsUrl, '_blank')
          break
        case 'USPS':
          window.open(uspsUrl, '_blank')
          break
        case 'FEDEX':
          window.open(fedexUrl, '_blank')
          break
        default:
          break
      }
    },
    closePopover() {
      this.isPopoverVisible = false
      this.isSupplierPopoverVisible = false
    },
    // DATAGRID METHODS
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filter.q = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filter.q = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filter.q = ''
            this.searchByFilter()
          }
        }
      }
    },
    async onDownloadReport(e) {
      AmazonSalesFilter.itemAction = this.isAll ? 'ALL' : this.itemAction
      const filters = AmazonSalesFilter.getFilters()
      const pageableQuery = 'page=0&size=10000'
      const response = await amazonReportService.returnsByQuery(pageableQuery, filters)
      const data = response.content
      this.onDownloadExportData(data)
    },
    onDownloadReportSelected() {
      const data = this.dataGrid.getSelectedRowsData()
      if (Array.isArray(data) && data.length > 0) {
        this.onDownloadExportData(data)
      } else {
        Notify.warning('Please select at least one item to export data')
      }
    },
    onDownloadExportData(data) {
      const fileDate = new Date().toDateString()
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(`Returns_${fileDate}`)
      worksheet.columns = [
        { header: 'Title', key: 'title', width: 100 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'MSKU', key: 'msku', width: 14 },
        { header: 'Order ID', key: 'orderId', width: 14 },
        { header: 'Quantity', key: 'quantity', width: 14 },
        { header: 'Status', key: 'status', width: 14 },
        { header: 'Purchase Date', key: 'purchaseDate', width: 14 },
        { header: 'Return Date', key: 'returnDate', width: 14 },
        { header: 'Tracking', key: 'tracking', width: 14 },
        { header: 'Customer Comment', key: 'customerComment', width: 14 },
        { header: 'Reason', key: 'reason', width: 14 },
        { header: 'Return Barcode', key: 'returnBarcode', width: 14 },
        { header: 'Detailed Disposition', key: 'detailedDisposition', width: 14 },
        { header: 'Channel', key: 'channel', width: 14 },
        { header: 'Order Amount', key: 'orderAmount', width: 10 },
        { header: 'Refund Amount', key: 'refundAmount', width: 10 },
        { header: 'Label Cost', key: 'labelCost', width: 12 },

      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.title,
          item.asin,
          item.upc,
          item.msku,
          item.orderId,
          item.quantity,
          item.status,
          item.purchaseDate,
          item.returnDate,
          item.tracking,
          item.customerComment,
          item.reason,
          item.returnBarcode,
          item.detailedDisposition,
          item.channel,
          item.orderAmount,
          item.refundAmount,
          item.labelCost,

        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Amazon_Returns_${fileDate}.xlsx`,
        )
      })
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'all',
              name: 'All Items',
              icon: 'selectall',
            },
            {
              id: 'selected',
              name: 'Selected',
              icon: 'exportselected',
            },
          ],
          width: 140,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Report',
          onItemClick: arg => {
            if (arg) {
              //
            }
            if (arg.itemData.id === 'all') {
              this.onDownloadReport()
            } else if (arg.itemData.id === 'selected') {
              this.onDownloadReportSelected()
            }
          },
        },

        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.resetAndRefreshDataGrid()
          },
        },
        location: 'after',
      })
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
